import React, {useEffect, useState} from 'react'
import '../App.css'
import axios from 'axios'

const logo = require('../imgs/DuelFlipLinearSpin.gif')
const blueHighlight = require('../imgs/BlueHighlight.png')
const redHighlight = require('../imgs/RedHighlight.png')


function Toaster () {
    
    return (
        <div className='toaster-container'>
            <h1>THANK YOU FOR SIGNING UP TO OUR WAIT LIST!</h1>
        </div>
    )
}

function Email() {
    const [email, setEmail] = useState('');
    const [fullname, setFullName] = useState('');
    const [signedUp, setSignedUp] = useState(false);
    const [showModal, setShowModal] = useState(false)

    useEffect(() => {
        if (localStorage.getItem("TDE")) {
            setSignedUp(true);
        }
    }, []);

    const sendReq = () => {
        if (!signedUp) {
            try {
                localStorage.setItem("TDE", true);
                setShowModal(true)

                axios.post(`https://api.tokenduels.gg/api/waitlist-signup`, {
                    fullname: fullname,
                    email: email
                })
                .then(response => {
                    console.log('signup successful', response.data);
                })
                .catch(error => {
                    console.error('Error during signup:', error);
                });
            } catch (error) {
                console.error('Error during signup:', error);
            }
        }
    }

    return (
        <div className='email-waitlist-container'>
            <img src={blueHighlight} className='blue-highlight' />
            {
                showModal && (
                    <Toaster/>

                )
            }
            <div className='email-waitlist-part'>
                <img src={logo} className='logo-spin' />
            </div>
            <div className='email-waitlist-part'>
                <div className='email-waitlist-headers'>
                    <h1>HEADS OR TAILS?</h1>
                    <h2>THE CHOICE IS YOURS. JOIN OUR WAITING LIST AND TEST YOUR LUCK!</h2>
                </div>
                <input 
                    className='email-input' 
                    autoComplete='name' 
                    placeholder='Full Name' 
                    onChange={(e) => setFullName(e.target.value)} 
                    disabled={signedUp}
                />
                <input 
                    className='email-input' 
                    autoComplete='email' 
                    placeholder='Email' 
                    onChange={(e) => setEmail(e.target.value)} 
                    disabled={signedUp}
                />
                <button 
                    className={signedUp ? "cant-click" : ""} 
                    onClick={sendReq}
                    disabled={signedUp}
                >
                    Join Waitlist!
                </button>
                {signedUp && <h1 className='already'>IT SEEMS YOU'VE ALREADY SIGNED UP!</h1>}
            </div>
            <img src={redHighlight} className='red-highlight' />
        </div>
    );
}

export default Email
