import './App.css';
import Email from './Components/Email.js'



function App() {
  return (
    <div className="App">
      <Email/>
    </div>
  );
}

export default App;
