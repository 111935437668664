// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./imgs/VCR_OSD_MONO_1.001.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Define the font with @font-face */
@font-face {
    font-family: 'VCR OSD Mono';
    src: 
         url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  
  /* Apply the font-family globally */
  body {
    max-height: 100vh;
    overflow-y: hidden;
    margin: 0;
    font-family: 'VCR OSD Mono', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #11191F;
    overflow-x:hidden;
  
  }
  
  /* Apply the font-family to all elements */
  * {
    font-family: 'VCR OSD Mono', sans-serif;
  }
  
  /* Set cursor for buttons */
  button {
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  button:hover {
    transform: scale(1.1);
  }
  
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA,oCAAoC;AACpC;IACI,2BAA2B;IAC3B;mEAC4D;IAC5D,mBAAmB;IACnB,kBAAkB;EACpB;;EAEA,mCAAmC;EACnC;IACE,iBAAiB;IACjB,kBAAkB;IAClB,SAAS;IACT,uCAAuC;IACvC,mCAAmC;IACnC,kCAAkC;IAClC,yBAAyB;IACzB,iBAAiB;;EAEnB;;EAEA,0CAA0C;EAC1C;IACE,uCAAuC;EACzC;;EAEA,2BAA2B;EAC3B;IACE,eAAe;IACf,yBAAyB;EAC3B;;EAEA;IACE,qBAAqB;EACvB","sourcesContent":["/* Define the font with @font-face */\n@font-face {\n    font-family: 'VCR OSD Mono';\n    src: \n         url('./imgs/VCR_OSD_MONO_1.001.ttf') format('truetype');\n    font-weight: normal;\n    font-style: normal;\n  }\n  \n  /* Apply the font-family globally */\n  body {\n    max-height: 100vh;\n    overflow-y: hidden;\n    margin: 0;\n    font-family: 'VCR OSD Mono', sans-serif;\n    -webkit-font-smoothing: antialiased;\n    -moz-osx-font-smoothing: grayscale;\n    background-color: #11191F;\n    overflow-x:hidden;\n  \n  }\n  \n  /* Apply the font-family to all elements */\n  * {\n    font-family: 'VCR OSD Mono', sans-serif;\n  }\n  \n  /* Set cursor for buttons */\n  button {\n    cursor: pointer;\n    transition: all 0.3s ease;\n  }\n  \n  button:hover {\n    transform: scale(1.1);\n  }\n  \n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
